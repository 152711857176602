<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Movimientos</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table :resource="resource" :columns="columns">
				<tr slot="heading">
					<th>N°</th>
					<th>FECHA</th>
					<th>TIPO</th>
					<th>TIPO DE PRODUCTO</th>
					<th>ALAMCÉN</th>
					<th>RESPONSABLE</th>
					<th>PRODUCTO</th>
					<th class="text-right">CANTIDAD</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td>{{ row.date_of_mov }}</td>
					<td>{{ row.type_description }}</td>
					<td>{{ row.item_type_description }}</td>
					<td>{{ row.warehouse }}</td>
					<td>{{ row.user }}</td>
					<td>{{ row.item_type == 'supply' ? row.ingredient.name : row.item.name }}</td>
					<td class="text-right">
						{{ row.quantity }}
					</td>
				</tr>
			</emb-data-table>
		</v-card>

		<movement-form :showDialog.sync="showDialog" :recordId="recordId"> </movement-form>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import MovementForm from './Form.vue';

export default {
	data() {
		return {
			showDialog: false,
			resource: 'movements',
			recordId: null,
			records: [],
			columns: {
				date_of_mov: 'Fecha de movimiento',
			},
		};
	},
	mixins: [deletable],
	components: {
		MovementForm,
	},
	methods: {
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
