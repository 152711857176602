<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		@open="create"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		append-to-body
		width="60%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.type }">
						<label class="control-label">Tipo</label>
						<el-select v-model="form.type">
							<el-option
								v-for="(option, index) in types"
								:key="index"
								:label="option.label"
								:value="option.id"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.type"
							v-text="errors.type[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.item_type }">
						<label class="control-label">Tipo de producto</label>
						<el-select v-model="form.item_type" @change="changeItemType">
							<el-option
								v-for="(option, index) in itemTypes"
								:key="index"
								:label="option.label"
								:value="option.id"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.item_type"
							v-text="errors.item_type[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4" lg="4" md="4" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.warehouse_id }">
						<label class="control-label">Almacén</label>
						<el-select v-model="form.warehouse_id">
							<el-option
								v-for="row in warehouses"
								:key="row.id"
								:label="row.name"
								:value="row.id"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.warehouse_id"
							v-text="errors.warehouse_id[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col
					cols="12"
					xl="6"
					lg="6"
					md="6"
					sm="12"
					v-if="form.item_type == 'no_transform'"
				>
					<div class="form-group" :class="{ 'has-danger': errors.item_id }">
						<label class="control-label">Producto</label>
						<el-select
							v-model="form.item_id"
							filterable
							remote
							reserve-keyword
							placeholder="Buscar..."
							:remote-method="searchRemoteItems"
							@change="changeItem"
							:loading="loadingSearch"
							loading-text="Cargando..."
							autofocus
						>
							<el-option
								v-for="item in items"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.item_id"
							v-text="errors.item_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6" lg="6" md="6" sm="12" v-else>
					<div class="form-group" :class="{ 'has-danger': errors.ingredient_id }">
						<label class="control-label">Producto</label>
						<el-select
							v-model="form.ingredient_id"
							filterable
							remote
							reserve-keyword
							placeholder="Buscar..."
							:remote-method="searchRemoteItems"
							@change="changeItem"
							:loading="loadingSearch"
							loading-text="Cargando..."
						>
							<el-option
								v-for="item in items"
								:key="item.id"
								:label="item.name"
								:value="item.id"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.ingredient_id"
							v-text="errors.ingredient_id[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.quantity }">
						<label class="control-label">Cantidad</label>
						<el-input v-model="form.quantity" class="input-text-right"></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.quantity"
							v-text="errors.quantity[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="12">
					<div class="form-group">
						<label class="control-label">Unidad M.</label>
						<el-select v-model="form.unit_type_id" filterable>
							<el-option
								v-for="option in unitTypes"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'has-danger': errors.motive }">
						<label class="control-label">Motivo</label>
						<el-input
							v-model="form.motive"
							class="input-text-right"
							type="textarea"
							rows="3"
						></el-input>
						<small
							class="form-control-feedback"
							v-if="errors.motive"
							v-text="errors.motive[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import find from 'lodash/find';
import dayjs from 'dayjs';
export default {
	props: ['showDialog'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'movements',
			errors: {},
			form: {},
			items: [],
			item_unit_types: [],
			loadingSearch: false,
			warehouses: [],
			unitTypes: [],
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') > time;
				},
			},
			types: [
				{ id: 'entry', label: 'Incremento' },
				{ id: 'exit', label: 'Disminución' },
			],
			itemTypes: [
				{ id: 'supply', label: 'Insumo' },
				{ id: 'no_transform', label: 'Producto no transformado' },
			],
		};
	},
	created() {},
	methods: {
		initForm() {
			this.items = [];
			this.errors = {};
			this.form = {
				id: null,
				type: 'entry',
				item_type: 'supply',
				warehouse_id: null,
				date_of_mov: dayjs().format('YYYY-MM-DD'),
				item_id: null,
				item: {},
				ingredient_id: null,
				ingredient: {},
				unit_type_id: 'KGM',
				quantity: 1,
			};
			this.item_unit_types = [];
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.unitTypes = response.data.unitTypes;
				this.warehouses = response.data.warehouses;

				this.form.warehouse_id = this.warehouses.length > 0 ? this.warehouses[0].id : null;
			});
		},
		async create() {
			this.titleDialog = 'Nuevo movimiento';

			await this.initForm();
			await this.getTables();
		},
		searchRemoteItems(input) {
			if (this.form.item_type) {
				if (input.length > 2) {
					this.loadingSearch = true;
					let parameters = `input=${input}&item_type=${this.form.item_type}`;
					this.$http
						.get(`/${this.resource}/search/items?${parameters}`)
						.then((response) => {
							this.items = response.data;
							this.loadingSearch = false;
						});
				} else {
					this.items = [];
				}
			} else {
				this.$message.error('Selecciona el tipo de producto para continuar.');
			}
		},
		changeItemType() {
			this.items = [];
		},
		changeItem() {
			if (this.form.item_type == 'no_transform') {
				this.form.item = find(this.items, { id: this.form.item_id });
			} else {
				this.form.ingredient = find(this.items, { id: this.form.ingredient_id });
			}
		},
		submit() {
			if (!this.form.quantity || this.form.quantity <= 0) {
				this.$message.error('La cantidad ingresada no es válida, corriga para continuar');
				return;
			}
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
					this.loading = false;
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
